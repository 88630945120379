@tailwind base;
@tailwind components;
@tailwind utilities;

:root { --h1: clamp(2.1875rem, 0.6458rem + 3.2118vw, 4.5rem); }
@layer base {
  @font-face {
    font-family: 'Andora Modern Script';
    src: url('./assets/fonts/AndoraModernScript.woff2') format('woff2'), url('./assets/fonts/AndoraModernScript.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Andora Modern Serif';
    src: url('./assets/fonts/AndoraModernSerif.woff2') format('woff2'), url('./assets/fonts/AndoraModernSerif.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy ExtraBold';
    src: url('./assets/fonts/Gilroy-ExtraBold.woff2') format('woff2'), url('./assets/fonts/Gilroy-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy Bold';
    src: url('./assets/fonts/Gilroy-Bold.woff2') format('woff2'), url('./assets/fonts/Gilroy-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy Heavy';
    src: url('./assets/fonts/Gilroy-Heavy.woff2') format('woff2'), url('./assets/fonts/Gilroy-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy Light';
    src: url('./assets/fonts/Gilroy-Light.woff2') format('woff2'), url('./assets/fonts/Gilroy-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy SemiBold';
    src: url('./assets/fonts/Gilroy-SemiBold.woff2') format('woff2'), url('./assets/fonts/Gilroy-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy Regular';
    src: url('./assets/fonts/Gilroy-Regular.woff2') format('woff2'), url('./assets/fonts/Gilroy-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Gilroy Medium';
    src: url('./assets/fonts/Gilroy-Medium.woff2') format('woff2'), url('./assets/fonts/Gilroy-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
}
body { @apply bg-[#161C21] text-[#ADB6BD] text-base leading-[1.3] font-gilroy-regular }
.container {
  @apply 2xl:max-w-[1654px] px-[30px] mx-auto
}
h1 { font-size: var(--h1); }
/* width */
.rt-customscroll::-webkit-scrollbar {
  width: 5px;
}
/* Track */
.rt-customscroll::-webkit-scrollbar-track {
  background: #161C21;
}
/* Handle */
.rt-customscroll::-webkit-scrollbar-thumb {
  background: #1C2329;
}
.rt-header {
  @apply py-8
}
.form-control {
  @apply pl-10 pr-24 py-[33px] w-full rounded-[44px] bg-[#161C21] border border-[#343D45] text-white placeholder:text-[#626669]
}
.rt-table-responsive {
  @apply w-full overflow-x-auto border-[#2B3339] border rounded-2xl
}
.rt-table {
  @apply w-full
}
.rt-table thead tr th {
  @apply py-6 px-8 text-base text-white border-[#2B3339] border-b whitespace-nowrap text-left /* font-gilroy-bold */
}
.rt-table tbody tr td {
  @apply py-4 px-8 text-base text-white font-gilroy-medium border-[#2B3339] border-b text-left align-middle whitespace-nowrap
}
.rt-table tbody tr:last-child td {
  @apply border-0
}
.rt-tag {
  @apply h-[23px] rounded-[61.233px] inline-flex items-center justify-center px-2 text-[11px] leading-[23px] min-w-[62px]
}
.addedRow > td{
}
.childTable {}
.AtThemeCheckbox input[type=checkbox]{@apply hidden}
.AtThemeCheckbox{@apply w-[48px] h-[28px]}
.AtThemeCheckbox label{@apply block w-[48px] h-[28px] rounded-[14px] bg-[#343D45] m-0 relative}
.AtThemeCheckbox input[type=checkbox]:checked + label{@apply bg-[#FDA14A]}
.AtThemeCheckbox label:after{
  @apply top-1/2 left-[5px] w-[18px] h-[18px] rounded-full bg-white absolute content-[''] -translate-y-1/2
}
.AtThemeCheckbox input[type=checkbox]:checked + label:after{@apply left-auto right-[5px]}
.rt-Scrollnone::-webkit-scrollbar{width: 0;}
.bg-pattern {
  @apply after:absolute after:w-full after:h-full after:top-0 after:left-0 after:z-[-1]
}
.bg-pattern:after {
   content: '';
  background: url(./assets/images/pattern-img.jpg) no-repeat;
  background-size: 100% 100;
}
@media (max-width: 900px){
  .rt-transection-table{
    overflow-x: auto;
    white-space: nowrap;
  }
}
.AtLoaderWrapper{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
}
.loader {
  width:45px;
  height:40px;
  background:
    linear-gradient(#FDA14A calc(1*100%/6),#161c21 0 calc(3*100%/6),#FDA14A 0),
    linear-gradient(#FDA14A calc(2*100%/6),#161c21 0 calc(4*100%/6),#FDA14A 0),
    linear-gradient(#FDA14A calc(3*100%/6),#161c21 0 calc(5*100%/6),#FDA14A 0);
  background-size:10px 400%;
  background-repeat: no-repeat;
  animation:matrix 1s infinite linear;
  }
  @keyframes matrix {
    0% {background-position: 0% 100%, 50% 100%, 100% 100% }
    100% {background-position: 0% 0%, 50% 0%, 100% 0% }
  }
  .AtNoDataFound{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - 86px);
  }
  